import Backoffice from "@snark/backoffice"

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

export default {
	name: "discount",
	collection: "discount",
	idProperty: "_id",
	section: "finance",
	labels: {"singleTitle":"Discount","listTitle":"Discounts"},
	emptyItem: {"enabled":true,"usage":[]},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "code",
			label: "Code",
		},
		{
			name: "left",
			label: "Utilisations restantes (-1 = illimité)",
			editor: {type: "float"},
		},
		{
			name: "startAt",
			label: "Start At",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "endAt",
			label: "End At",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "enabled",
			label: "Enabled",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "burnt",
			label: "Burnt",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "type",
			label: "Type",
			formatter: Backoffice.formatters.mapping({"percent": "Percent", "fixed": "Fixed", "cappedPercent": "Capped Percent"}),
			editor: {type: "select", options: [{value: "percent", label: "Percent"}, {value: "fixed", label: "Fixed"}, {value: "cappedPercent", label: "Capped Percent"}]},
		},
		{
			name: "percentage",
			label: "Percentage",
			editor: {type: "float"},
		},
		{
			name: "amount",
			label: "Amount",
			editor: {type: "float"},
		},
		{
			name: "usage",
			label: "Usage",
			readOnly: true,
			list: true,
			children: [
				{
					name: "_id",
					label: "Id",
					readOnly: true,
					hideOnCreate: true,
				},
				{
					name: "discountId",
					label: "Discount Id",
				},
				{
					name: "sessionId",
					label: "Session Id",
				},
				{
					name: "userId",
					label: "User Id",
				},
				{
					name: "discountCode",
					label: "Discount Code",
				},
				{
					name: "date",
					label: "Date",
					formatter: Backoffice.formatters.date("DD/MM/YYYY"),
					editor: {type: "date"},
				},
				{
					name: "blocked",
					label: "Blocked",
					formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
					editor: {type: "boolean", labels: ["Non", "Oui"]},
				},
				{
					name: "amount",
					label: "Montant (centimes)",
					editor: {type: "float"},
				},
			],
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "code",
			label: "Code",
		},
		{
			name: "left",
			label: "Utilisations restantes (-1 = illimité)",
		},
		{
			name: "startAt",
			label: "Start At",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "endAt",
			label: "End At",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "enabled",
			label: "Enabled",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
		},
		{
			name: "type",
			label: "Type",
			formatter: Backoffice.formatters.mapping({"percent": "Percent", "fixed": "Fixed", "cappedPercent": "Capped Percent"}),
		},
		{
			name: "percentage",
			label: "Percentage",
		},
		{
			name: "amount",
			label: "Amount",
		},
	],
};