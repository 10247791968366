import Vue from "vue";
import Backoffice from "@snark/backoffice";

import crudBouser from "./crud/bouser";
import crudUser from "./crud/user";
import crudRecipe from "./crud/recipe";
import crudSession from "./crud/session";
import crudReservation from "./crud/reservation";
import crudContact from "./crud/contact";
import crudMentorRequest from "./crud/mentor_request";
import crudSessionRequest from "./crud/session_request";
import crudTestimony from "./crud/testimony";
import crudDiscount from "./crud/discount";
import crudFaq from "./crud/faq";
import crudBlogCategory from "./crud/blog_category";
import crudBlog from "./crud/blog";
import crudLegals from "./crud/legals";
import crudHero from "./crud/hero";

import moment from "moment-timezone";
import axios from "axios";
moment.tz.setDefault('Europe/Paris');
moment.locale('fr');

Vue.config.productionTip = false;

Vue.use(Backoffice, {
    title: "Livecook - BO",
    debug: true,
    apiUrl: process.env.VUE_APP_API_URL,
    apiVersion: 2,
    gmapApiKey: process.env.VUE_APP_GMAP_KEY,
    logAs: {
        token: process.env.VUE_APP_LOGAS_TOKEN,
        frontUrl: process.env.VUE_APP_LOGAS_FRONT_URL,
        apiRoute: process.env.VUE_APP_LOGAS_API_ROUTE
    },
    authenticate: async function (login, password) {
        try {
            const result = await axios.post(process.env.VUE_APP_API_AUTHENTICATE_URL, {
                username: login,
                password: password
            });
            if (result.data.token) {
                return {token: result.data.token};
            }
        }
        catch(err) {
            console.log(err);
        }
        throw "unauthorized";
    },
    crud: async function () {
        // app
        Vue.crud(crudUser);
        Vue.crud(crudRecipe);
        Vue.crud(crudSession);
        Vue.crud(crudReservation);

        // interactions
        Vue.crud(crudContact);
        Vue.crud(crudMentorRequest);
        Vue.crud(crudSessionRequest);
        Vue.crud(crudTestimony);

        // finance
        Vue.crud(crudDiscount);

        // site
        Vue.crud(crudFaq);
        Vue.crud(crudBlogCategory);
        Vue.crud(crudBlog);
        Vue.crud(crudLegals);
        Vue.crud(crudHero);

        // backoffice
        Vue.crud(crudBouser);
    },
    defaultCRUDOptions: {
        editor: {
            select: {
                sort: 'asc'
            },
            checks: {
                sort: 'asc'
            }
        }
    },
    //routes: {
    //    prepare: function (routes) {
    //    }
    //},
    menuSections: ["app", "interactions", "finance", "site", "backoffice"],
    dashboardBlocks: []
});

Vue.backoffice.start(Vue);
