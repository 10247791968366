import Backoffice from "@snark/backoffice"
import {formatPictures} from "@/helpers/formatters";

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

export default {
	name: "recipe",
	collection: "recipe",
	idProperty: "_id",
	section: "app",
	labels: {"singleTitle":"Recipe","listTitle":"Recipes"},
	emptyItem: {"tags":[],"ingredients":[],"tools":[],"pictures":[],"notesCount":0},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "mentorId",
			label: "Mentor Id",
		},
		{
			name: "mentorName",
			label: "Mentor Name",
		},
		{
			name: "name",
			label: "Name",
		},
		{
			name: "slug",
			label: "Slug",
			readOnly: true,
		},
		{
			name: "kind",
			label: "Kind",
			formatter: Backoffice.formatters.mapping({"starter": "Starter", "mainCourse": "Main Course", "dessert": "Dessert", "other": "Other"}),
			editor: {type: "select", options: [{value: "starter", label: "Starter"}, {value: "mainCourse", label: "Main Course"}, {value: "dessert", label: "Dessert"}, {value: "other", label: "Other"}]},
		},
		{
			name: "duration",
			label: "Duration",
			editor: {"type":"integer","min":0},
		},
		{
			name: "difficulty",
			label: "Difficulty",
			formatter: Backoffice.formatters.mapping({"easy": "Easy", "medium": "Medium", "hard": "Hard"}),
			editor: {type: "select", options: [{value: "easy", label: "Easy"}, {value: "medium", label: "Medium"}, {value: "hard", label: "Hard"}]},
		},
		{
			name: "guestsCount",
			label: "Guests Count",
			editor: {"type":"integer","min":0},
		},
		{
			name: "description",
			label: "Description",
			formatter: Backoffice.formatters.multiLines(),
			editor: {"type":"text","lines":5},
		},
		{
			name: "tags",
			label: "Tags",
			list: true,
			children: [
			],
		},
		{
			name: "ingredients",
			label: "Ingredients",
			list: true,
			children: [
			],
		},
		{
			name: "tools",
			label: "Tools",
			list: true,
			children: [
			],
		},
		{
			name: "cover",
			label: "Cover",
			formatter: Backoffice.formatters.subProperty('path', Backoffice.formatters.picture({urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",maxHeight: 300,})),
			editor: {
				type: "image",
				accept: "image/png,image/jpeg",
				preview: {
					urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",
					contain: true,
					formatUrl: Backoffice.formatters.subProperty('path'),
				}
			},
		},
		{
			name: "pictures",
			label: "Pictures",
			readOnly: true,
			formatter: formatPictures,
			list: true,
			children: [
				{
					name: "path",
					label: "Path",
				},
				{
					name: "thumbnail",
					label: "Thumbnail",
				},
				{
					name: "mimetype",
					label: "Mimetype",
				},
			],
		},
		{
			name: "notesCount",
			label: "Notes Count",
			readOnly: true,
		},
		{
			name: "note",
			label: "Note",
			readOnly: true,
		},
		{
			name: "favorite",
			label: "Favorite",
			readOnly: true,
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "mentorName",
			label: "Mentor Name",
		},
		{
			name: "name",
			label: "Name",
		},
		{
			name: "kind",
			label: "Kind",
			formatter: Backoffice.formatters.mapping({"starter": "Starter", "mainCourse": "Main Course", "dessert": "Dessert", "other": "Other"}),
		},
	],
	beforeSave: function (item, _newItem) {
            return __awaiter(this, void 0, void 0, function* () {
                delete item.mentor;
                delete item.sessions;
                delete item.nextSession;
                return item;
            });
        },
};