import Backoffice from "@snark/backoffice"

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

export default {
	name: "hero",
	collection: "hero",
	idProperty: "_id",
	section: "site",
	backTextFilterList: true,
	labels: {"singleTitle":"Hero","listTitle":"Heros"},
	emptyItem: {},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "page",
			label: "Page",
			formatter: Backoffice.formatters.mapping({"Accueil": "Accueil", "Devenir Coach": "Devenir Coach", "Contact": "Contact"}),
			editor: {type: "select", options: [{value: "Accueil", label: "Accueil"}, {value: "Devenir Coach", label: "Devenir Coach"}, {value: "Contact", label: "Contact"}]},
		},
		{
			name: "smallTitle",
			label: "Small Title",
		},
		{
			name: "title",
			label: "Title",
		},
		{
			name: "text",
			label: "Text",
			formatter: Backoffice.formatters.multiLines(),
			editor: {"type":"text","lines":5},
		},
		{
			name: "buttonLabel",
			label: "Button Label",
		},
		{
			name: "redirect",
			label: "Redirect",
		},
		{
			name: "media",
			label: "Media",
			formatter: Backoffice.formatters.subProperty('path', Backoffice.formatters.picture({urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",maxHeight: 300,})),
			editor: {
				type: "image",
				accept: "image/png,image/jpeg,video/mp4",
				preview: {
					urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",
					height: 300,
					contain: true,
					formatUrl: Backoffice.formatters.subProperty('path'),
				}
			},
		},
		{
			name: "activated",
			label: "Activated",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "page",
			label: "Page",
			formatter: Backoffice.formatters.mapping({"Accueil": "Accueil", "Devenir Coach": "Devenir Coach", "Contact": "Contact"}),
		},
		{
			name: "smallTitle",
			label: "Small Title",
		},
		{
			name: "title",
			label: "Title",
		},
		{
			name: "text",
			label: "Text",
			formatter: Backoffice.formatters.multiLines(),
		},
		{
			name: "buttonLabel",
			label: "Button Label",
		},
		{
			name: "redirect",
			label: "Redirect",
		},
		{
			name: "activated",
			label: "Activated",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
		},
	],
};