import Backoffice from "@snark/backoffice"

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

const context = {
	blog_category: Backoffice.populator("blog_category", {
		idProperty: "slug",
	}),
};

export default {
	name: "blog",
	collection: "blog",
	idProperty: "_id",
	section: "site",
	labels: {"singleTitle":"Blog","listTitle":"Blogs"},
	emptyItem: {"tags":[],"categories":[],"content":[]},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "title",
			label: "Title",
		},
		{
			name: "slug",
			label: "Slug",
			readOnly: true,
		},
		{
			name: "excerpt",
			label: "Excerpt",
			formatter: Backoffice.formatters.multiLines(),
			editor: {"type":"text","lines":5},
		},
		{
			name: "date",
			label: "Date",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "mainPicture",
			label: "Main Picture",
			formatter: Backoffice.formatters.subProperty('path', Backoffice.formatters.picture({urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",maxHeight: 300,})),
			editor: {
				type: "image",
				accept: "image/png,image/jpeg",
				preview: {
					urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",
					height: 300,
					formatUrl: Backoffice.formatters.subProperty('path'),
				}
			},
		},
		{
			name: "author",
			label: "Author",
		},
		{
			name: "tags",
			label: "Tags",
			emptyItem: "",
			list: true,
		},
		{
			name: "categories",
			label: "Categories",
			list: true,
			children: [
				{
					name: "_item",
					formatter: context.blog_category.formatter(),
					editor: context.blog_category.editor(),
				},
			],
		},
		{
			name: "published",
			label: "Published",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "content",
			label: "Content",
			list: true,
			children: [
				{
					label: "Sections",
					formatter: Backoffice.formatters.richBlock(),
					editor: {"type":"custom","component":"richblock"},
				},
			],
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "title",
			label: "Title",
		},
		{
			name: "date",
			label: "Date",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	beforeSave: function (item, _newItem) {
            return __awaiter(this, void 0, void 0, function* () {
                delete item.populatedCategories;
                return item;
            });
        },
	loadSingle() {
		return Promise.all([
			context.blog_category.load(),
		]);
	},
};