import Backoffice from "@snark/backoffice"
import {userBecomeMentor} from "@/helpers/actions";

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

const context = {
	recipe: Backoffice.populator("recipe", {
	}),
};

export default {
	name: "user",
	collection: "user",
	idProperty: "_id",
	section: "app",
	sort: {"back":[{"field":"createdAt","order":"desc"}]},
	pagination: 30,
	backTextFilterList: true,
	viewActions: [
		{
			label: "Faire devenir mentor",
			cssClass: undefined,
			check: function (item) {
                    return item.mentor !== true;
                },
			action: userBecomeMentor,
		},
		{
			type: "logAs",
			logAs: {"field":"email"},
		},
	],
	labels: {"singleTitle":"User","listTitle":"Users"},
	emptyItem: {"keywords":[],"favoriteRecipes":[]},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "email",
			label: "Email",
		},
		{
			name: "password",
			label: "Password",
			writeOnly: true,
		},
		{
			name: "firstname",
			label: "Firstname",
		},
		{
			name: "lastname",
			label: "Lastname",
		},
		{
			name: "pseudo",
			label: "Pseudo",
		},
		{
			name: "slug",
			label: "Slug",
		},
		{
			name: "phoneNumber",
			label: "Phone Number",
		},
		{
			name: "addressName",
			label: "Address Name",
		},
		{
			name: "address",
			label: "Address",
		},
		{
			name: "zip",
			label: "Zip",
		},
		{
			name: "city",
			label: "City",
		},
		{
			name: "spokenLanguages",
			label: "Spoken Languages",
		},
		{
			name: "status",
			label: "Status",
			formatter: Backoffice.formatters.mapping({"created": "Created"}),
			editor: {type: "select", options: [{value: "created", label: "Created"}]},
		},
		{
			name: "cguAccepted",
			label: "Cgu Accepted",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "legalAgeDeclaration",
			label: "Legal Age Declaration",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "picture",
			label: "Picture",
			formatter: Backoffice.formatters.subProperty('path', Backoffice.formatters.picture({urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",maxHeight: 300,})),
			editor: {
				type: "image",
				accept: "image/png,image/jpeg",
				preview: {
					urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",
					contain: true,
					formatUrl: Backoffice.formatters.subProperty('path'),
				}
			},
		},
		{
			name: "description",
			label: "Description",
			formatter: Backoffice.formatters.multiLines(),
			editor: {"type":"text","lines":5},
		},
		{
			name: "globalNote",
			label: "Global Note",
			editor: {type: "float"},
		},
		{
			name: "notesCount",
			label: "Notes Count",
			editor: {type: "float"},
		},
		{
			name: "keywords",
			label: "Keywords",
			formatter: Backoffice.formatters.tags(undefined, ""),
			editor: {"type":"tags","valueFormat":"array"},
			list: true,
		},
		{
			name: "facebook",
			label: "Facebook",
		},
		{
			name: "youtube",
			label: "Youtube",
		},
		{
			name: "instagram",
			label: "Instagram",
		},
		{
			name: "tiktok",
			label: "Tiktok",
		},
		{
			name: "web",
			label: "Web",
		},
		{
			name: "preferedLanguage",
			label: "Prefered Language",
		},
		{
			name: "favoriteRecipes",
			label: "Favorite Recipes",
			list: true,
			children: [
				{
					name: "recipeId",
					label: "Recipe Id",
					formatter: Backoffice.formatters.linkObjectId("recipe", {idProperty: "_id","label": context.recipe.formatter()}),
					editor: context.recipe.editor(),
				},
				{
					name: "date",
					label: "Date",
					formatter: Backoffice.formatters.date("DD/MM/YYYY"),
					editor: {type: "date"},
				},
			],
		},
		{
			name: "mentor",
			label: "Mentor",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "mentorSince",
			label: "Mentor Since",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "stripeId",
			label: "Stripe Id",
			readOnly: true,
		},
		{
			name: "customerStripeId",
			label: "Customer Stripe Id",
			readOnly: true,
		},
		{
			name: "paymentConfigurationCompleted",
			label: "Payment Configuration Completed",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "state",
			label: "State",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.mapping({"deleted": "Deleted", "draft": "Draft"}),
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "token",
			label: "Token",
		},
		{
			name: "tokenExpire",
			label: "Token Expire",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "resetToken",
			label: "Reset Token",
		},
		{
			name: "resetTokenExpire",
			label: "Reset Token Expire",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "firstRecipeCreated",
			label: "First Recipe Created",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
	],
	listColumns: [
		{
			name: "email",
			label: "Email",
		},
		{
			name: "firstname",
			label: "Firstname",
		},
		{
			name: "lastname",
			label: "Lastname",
		},
		{
			name: "pseudo",
			label: "Pseudo",
		},
		{
			name: "mentor",
			label: "Mentor",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	beforeSave: function (item, _newItem) {
            return __awaiter(this, void 0, void 0, function* () {
                delete item.recipes;
                return item;
            });
        },
	loadSingle() {
		return Promise.all([
			context.recipe.load(),
		]);
	},
};