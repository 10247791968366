import Backoffice from "@snark/backoffice"

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

const context = {
	user: Backoffice.populator("user", {
		displayProperty: item => {
                    return item.firstname + " " + item.lastname;
                },
	}),
};

export default {
	name: "testimony",
	collection: "testimony",
	idProperty: "_id",
	section: "interactions",
	sort: {"back":[{"field":"createdAt","order":"desc"}]},
	pagination: 30,
	labels: {"singleTitle":"Testimony","listTitle":"Testimonies"},
	emptyItem: {},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "userId",
			label: "User Id",
			readOnly: true,
			formatter: Backoffice.formatters.linkObjectId("user", {idProperty: "_id","label": context.user.formatter()}),
		},
		{
			name: "fullname",
			label: "Fullname",
		},
		{
			name: "quote",
			label: "Quote",
			formatter: Backoffice.formatters.multiLines(),
			editor: {"type":"text","lines":5},
		},
		{
			name: "date",
			label: "Date",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "type",
			label: "Type",
			formatter: Backoffice.formatters.mapping({"explorer": "Explorer", "mentor": "Mentor"}),
			editor: {type: "select", options: [{value: "explorer", label: "Explorer"}, {value: "mentor", label: "Mentor"}]},
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "fullname",
			label: "Fullname",
		},
		{
			name: "date",
			label: "Date",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "type",
			label: "Type",
			formatter: Backoffice.formatters.mapping({"explorer": "Explorer", "mentor": "Mentor"}),
		},
	],
	beforeSave: function (item, _newItem) {
            return __awaiter(this, void 0, void 0, function* () {
                delete item.user;
                return item;
            });
        },
	loadSingle() {
		return Promise.all([
			context.user.load(),
		]);
	},
};