export function goFormatter(field, item) {
    const val = item[field.name];
    if (val && val > 0) {
        return (val / 1024 / 1024 / 1024).toFixed(1);
    } else {
        return "-";
    }
}

export function formatPictures(field, item) {
    const baseUrl = process.env.VUE_APP_API_STATIC_URL;
    return item.pictures ? item.pictures
            .map(pic => {
                return `<img src=${baseUrl + pic.path} style="display:inline-block; width: 100px; height: 100px; object-fit: cover; margin: 10px" crossorigin="anonymous" alt="picture"/>`;
            })
            .join("\n")
        : "-";
}

export function formatCommentNotes(field, item) {
    const comment = item[field.name];
    if (comment) {
        return "<ul style='line-height: 1em; padding: 0;'>" +
            "<li>mentor: " + comment.mentorNote + "</li>" +
            "<li>recette: " + comment.recipeNote + "</li>" +
            "<li>tech: " + comment.technicalNote + "</li>" +
            "</ul>";
    }
    else {
        return "-";
    }
}

export function formatComment(field, item) {
    const comment = item[field.name];
    if (comment) {
        let text = "(no comment)";
        if(comment.feedback) {
            text = comment.feedback.split("\n").map((p) => {
                return "<p>" + p + "</p>";
            }).join("");
        }
        return "<ul style='line-height: 1em; padding: 0;'>" +
            "<li>mentor: " + comment.mentorNote + "</li>" +
            "<li>recette: " + comment.recipeNote + "</li>" +
            "<li>tech: " + comment.technicalNote + "</li>" +
            "</ul>" +
            "<div>" + text + "</div>";
    }
    else {
        return "-";
    }
}