import Backoffice from "@snark/backoffice"

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

const context = {
	user: Backoffice.populator("user", {
		displayProperty: item => {
                    return item.firstname + " " + item.lastname;
                },
	}),
	recipe: Backoffice.populator("recipe", {
	}),
};

export default {
	name: "session_request",
	collection: "session_request",
	idProperty: "_id",
	section: "interactions",
	sort: {"back":[{"field":"createdAt","order":"desc"}]},
	pagination: 30,
	backPopulateList: ["user","recipe"],
	backTextFilterList: true,
	noCreate: true,
	labels: {"singleTitle":"Session Request","listTitle":"Session Requests"},
	emptyItem: {},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "userId",
			label: "User Id",
		},
		{
			name: "recipeId",
			label: "Recipe Id",
		},
		{
			name: "mentorId",
			label: "Mentor Id",
		},
		{
			name: "date",
			label: "Date",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "flexible",
			label: "Flexible",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "askMode",
			label: "Ask Mode",
			formatter: Backoffice.formatters.mapping({"oneToOne": "One To One", "group": "Group", "whatever": "Whatever"}),
			editor: {type: "select", options: [{value: "oneToOne", label: "One To One"}, {value: "group", label: "Group"}, {value: "whatever", label: "Whatever"}]},
		},
		{
			name: "message",
			label: "Message",
		},
		{
			name: "answer",
			label: "Answer",
		},
		{
			name: "status",
			label: "Status",
			formatter: Backoffice.formatters.mapping({"asked": "Asked", "refused": "Refused", "accepted": "Accepted"}),
			editor: {type: "select", options: [{value: "asked", label: "Asked"}, {value: "refused", label: "Refused"}, {value: "accepted", label: "Accepted"}]},
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "user",
			label: "User",
			formatter: Backoffice.formatters.fullName(),
			nosingle: true,
		},
		{
			name: "recipe",
			label: "Recipe",
			formatter: Backoffice.formatters.subProperty('name'),
			nosingle: true,
		},
	],
	beforeSave: function (item, _newItem) {
            return __awaiter(this, void 0, void 0, function* () {
                delete item.user;
                delete item.recipe;
                return item;
            });
        },
	loadSingle() {
		return Promise.all([
			context.user.load(),
			context.recipe.load(),
		]);
	},
};