import Backoffice from "@snark/backoffice"

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

const context = {
	user: Backoffice.populator("user", {
		displayProperty: item => {
                    return item.firstname + " " + item.lastname;
                },
	}),
	recipe: Backoffice.populator("recipe", {
	}),
};

export default {
	name: "session",
	collection: "session",
	idProperty: "_id",
	section: "app",
	sort: {"back":[{"field":"createdAt","order":"desc"}]},
	pagination: 30,
	backPopulateList: ["mentor","recipe"],
	backTextFilterList: true,
	noCreate: true,
	labels: {"singleTitle":"Session","listTitle":"Sessions"},
	emptyItem: {},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "mentorId",
			label: "Mentor Id",
		},
		{
			name: "recipeId",
			label: "Recipe Id",
		},
		{
			name: "date",
			label: "Date",
			formatter: Backoffice.formatters.date("DD/MM/YYYY - HH:mm"),
			editor: {type: "date"},
		},
		{
			name: "duration",
			label: "Duration",
			editor: {"type":"integer","min":0},
		},
		{
			name: "minSeats",
			label: "Min Seats",
			editor: {"type":"integer","min":1},
		},
		{
			name: "seats",
			label: "Seats",
			editor: {"type":"integer","min":1},
		},
		{
			name: "leftSeats",
			label: "Left Seats",
			readOnly: true,
		},
		{
			name: "price",
			label: "Price",
			formatter: Backoffice.formatters.price(),
			editor: {type: "float", mode: "price"},
		},
		{
			name: "status",
			label: "Status",
			formatter: Backoffice.formatters.mapping({"created": "Created", "prepared": "Prepared", "inprogress": "Inprogress", "done": "Done", "canceled": "Canceled", "archived": "Archived"}),
			editor: {type: "select", options: [{value: "created", label: "Created"}, {value: "prepared", label: "Prepared"}, {value: "inprogress", label: "Inprogress"}, {value: "done", label: "Done"}, {value: "canceled", label: "Canceled"}, {value: "archived", label: "Archived"}]},
		},
		{
			name: "videoEventId",
			label: "Video Event Id",
			readOnly: true,
		},
		{
			name: "videoSessionId",
			label: "Video Session Id",
			readOnly: true,
		},
		{
			name: "mentorVideoId",
			label: "Mentor Video Id",
			readOnly: true,
		},
		{
			name: "mentorVideoLink",
			label: "Mentor Video Link",
		},
		{
			name: "dayBeforeMentorReminderSent",
			label: "Day Before Mentor Reminder Sent",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "confirmSession",
			label: "Confirm Session",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "state",
			label: "State",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.mapping({"deleted": "Deleted", "draft": "Draft"}),
		},
	],
	listColumns: [
		{
			name: "date",
			label: "Date",
			formatter: Backoffice.formatters.date("DD/MM/YYYY - HH:mm"),
		},
		{
			name: "minSeats",
			label: "Min Seats",
		},
		{
			name: "seats",
			label: "Seats",
		},
		{
			name: "price",
			label: "Price",
			formatter: Backoffice.formatters.price(),
		},
		{
			name: "status",
			label: "Status",
			formatter: Backoffice.formatters.mapping({"created": "Created", "prepared": "Prepared", "inprogress": "Inprogress", "done": "Done", "canceled": "Canceled", "archived": "Archived"}),
		},
		{
			name: "mentor",
			label: "Mentor",
			formatter: Backoffice.formatters.fullName(),
			nosingle: true,
		},
		{
			name: "recipe",
			label: "Recipe",
			formatter: Backoffice.formatters.subProperty('name'),
			nosingle: true,
		},
	],
	beforeSave: function (item, _newItem) {
            return __awaiter(this, void 0, void 0, function* () {
                delete item.mentor;
                delete item.recipe;
                delete item.reservations;
                delete item.participants;
                delete item.favorite;
                return item;
            });
        },
	loadSingle() {
		return Promise.all([
			context.user.load(),
			context.recipe.load(),
		]);
	},
};