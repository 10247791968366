import axios from "axios";

export async function userBecomeMentor(item) {
    try {
        const result = await axios.post(process.env.VUE_APP_BASE_API_URL + "/user/" + item._id + "/become-mentor", {}, {
            headers: {
                'X-User-Token': localStorage.getItem("BACKOFFICE_TOKEN")
            }
        });
        console.log("RESULT = ", result.data);
        return {
            reload: true,
            message: "L'utilisateur' est maintenant Mentor"
        };
    }
    catch(err) {
        console.log("Error user become Mentor: ", err);
        if(err.response && err.response.data && Array.isArray(err.response.data) && err.response.data.length > 0) {
            if(err.response.data[0].id === "alreadymentor") {
                throw "L'utilisateur est déjà mentor";
            }
        }

        throw "Problème. L'utilisateur ne peut être transformé en Mentor.";
    }
}

export async function generateInvoice(item) {
    try {
        const result = await axios.post(process.env.VUE_APP_BASE_API_URL + "/reservation/" + item._id + "/generate-invoice", {}, {
            headers: {
                'X-User-Token': localStorage.getItem("BACKOFFICE_TOKEN")
            }
        });
        console.log("RESULT = ", result.data);
        return {
            reload: true,
            message: "La facture a bien été regénérée et est accessible dans l'historique."
        };
    }
    catch(err) {
        console.log("Error user become Mentor: ", err);
        throw "Problème. La facture n'a pu être regénérée";
    }
}
