import Backoffice from "@snark/backoffice"

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

export default {
	name: "mentor_request",
	collection: "mentor_request",
	idProperty: "_id",
	section: "interactions",
	backPopulateList: ["user"],
	readOnly: true,
	labels: {"singleTitle":"Mentor Request","listTitle":"Mentor Requests"},
	emptyItem: {},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "userId",
			label: "User Id",
		},
		{
			name: "status",
			label: "Status",
			formatter: Backoffice.formatters.mapping({"created": "Created", "refused": "Refused", "accepted": "Accepted"}),
			editor: {type: "select", options: [{value: "created", label: "Created"}, {value: "refused", label: "Refused"}, {value: "accepted", label: "Accepted"}]},
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "user",
			label: "User",
			formatter: Backoffice.formatters.fullName(),
			nosingle: true,
		},
		{
			name: "status",
			label: "Status",
			formatter: Backoffice.formatters.mapping({"created": "Created", "refused": "Refused", "accepted": "Accepted"}),
		},
	],
	beforeSave: function (item, _newItem) {
            return __awaiter(this, void 0, void 0, function* () {
                delete item.user;
                return item;
            });
        },
};