import Backoffice from "@snark/backoffice"
import {generateInvoice} from "@/helpers/actions";
import {formatCommentNotes, formatComment} from "@/helpers/formatters";

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

const context = {
	user: Backoffice.populator("user", {
		displayProperty: item => {
                    return item.firstname + " " + item.lastname;
                },
	}),
	session: Backoffice.populator("session", {
	}),
};

export default {
	name: "reservation",
	collection: "reservation",
	idProperty: "_id",
	section: "app",
	sort: {"back":[{"field":"createdAt","order":"desc"}]},
	pagination: 30,
	backPopulateList: ["user","session","mentor"],
	backTextFilterList: true,
	noCreate: true,
	viewActions: [
		{
			label: "Regénérer la facture",
			cssClass: undefined,
			action: generateInvoice,
		},
	],
	labels: {"singleTitle":"Reservation","listTitle":"Reservations"},
	emptyItem: {"ingredients":[],"tools":[]},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "userId",
			label: "User Id",
		},
		{
			name: "userName",
			label: "User Name",
		},
		{
			name: "sessionId",
			label: "Session Id",
		},
		{
			name: "mentorId",
			label: "Mentor Id",
		},
		{
			name: "sessionDate",
			label: "Session Date",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "price",
			label: "Price",
		},
		{
			name: "status",
			label: "Status",
			formatter: Backoffice.formatters.mapping({"started": "Started", "paid": "Paid", "error": "Error", "canceled": "Canceled", "toEvaluate": "To Evaluate", "done": "Done", "archived": "Archived", "waiting": "Waiting"}),
			editor: {type: "select", options: [{value: "started", label: "Started"}, {value: "paid", label: "Paid"}, {value: "error", label: "Error"}, {value: "canceled", label: "Canceled"}, {value: "toEvaluate", label: "To Evaluate"}, {value: "done", label: "Done"}, {value: "archived", label: "Archived"}, {value: "waiting", label: "Waiting"}]},
		},
		{
			name: "canceledAt",
			label: "Canceled At",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "videoReservationId",
			label: "Video Reservation Id",
		},
		{
			name: "videoLink",
			label: "Video Link",
		},
		{
			name: "ingredients",
			label: "Ingredients",
			list: true,
		},
		{
			name: "tools",
			label: "Tools",
			list: true,
		},
		{
			name: "userEmail",
			label: "User Email",
		},
		{
			name: "giftMessage",
			label: "Gift Message",
		},
		{
			name: "giftDate",
			label: "Gift Date",
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
			editor: {type: "date"},
		},
		{
			name: "buyerId",
			label: "Buyer Id",
		},
		{
			name: "isGift",
			label: "Is Gift",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "evaluationNotificationSent",
			label: "Evaluation Notification Sent",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "evaluationReminderSent",
			label: "Evaluation Reminder Sent",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "explorerReminderSent",
			label: "Explorer Reminder Sent",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "explorerTodayReminderSent",
			label: "Explorer Today Reminder Sent",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "invoiceNumber",
			label: "Invoice Number",
			editor: {type: "float"},
		},
		{
			name: "receiptNumber",
			label: "Receipt Number",
			editor: {type: "float"},
		},
		{
			name: "stripePaymentIntent",
			label: "Stripe Payment Intent",
		},
		{
			name: "comment",
			label: "Comment",
			formatter: formatComment,
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "session",
			label: "Session",
			formatter: Backoffice.formatters.subProperty('date', Backoffice.formatters.date('DD/MM/YYYY - HH:mm')),
			nosingle: true,
		},
		{
			name: "user",
			label: "User",
			formatter: Backoffice.formatters.fullName(),
			nosingle: true,
		},
		{
			name: "mentor",
			label: "Mentor",
			formatter: Backoffice.formatters.fullName(),
			nosingle: true,
		},
		{
			name: "invoiceNumber",
			label: "Invoice Number",
		},
		{
			name: "receiptNumber",
			label: "Receipt Number",
		},
		{
			name: "comment",
			label: "Comment",
			formatter: formatCommentNotes,
		},
	],
	beforeSave: function (item, _newItem) {
            return __awaiter(this, void 0, void 0, function* () {
                delete item.session;
                delete item.user;
                delete item.mentor;
                return item;
            });
        },
	loadSingle() {
		return Promise.all([
			context.user.load(),
			context.session.load(),
		]);
	},
};